<template>
  <section class="options-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-1"></div>
        <div class="col-lg-10">
          <div class="form-options">
            <form method="post" accept-charset="utf-8" @submit.prevent>
              <div class="one-half one-half2 number-pass">
                <label for="number-pass">Number Of Passengers</label>
                <div class="select">
                  <select
                    name="number-pass"
                    id="number-pass"
                    v-model="numberPassenger"
                  >
                    <option
                      v-for="index in maxPersons"
                      :key="index"
                      :value="index"
                    >
                      {{ index }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="one-half one-half2 number-bags">
                <label for="number-bags">Number Of Bags</label>
                <div class="select">
                  <select
                    name="number-pass"
                    id="number-pass"
                    v-model="numberBags"
                  >
                    <option
                      v-for="index in maxBags"
                      :key="index"
                      :value="index"
                    >
                      {{ index }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="one-half one-half2 number-bags">
                <label for="number-bags">Occasion</label>
                <div class="select">
                  <select
                    name="number-pass"
                    id="number-pass"
                    v-model="occasion"
                  > 
                    <option value="0">Airport Transfer</option>
                    <option value="1">Graduation</option>
                    <option value="2">Wedding Send-off</option>
                    <option value="3">Brithday/quinceañera</option>
                    <option value="4">Bachelor/Bachelorette Party</option>
                    <option value="5">Other</option>
                  </select>
                </div>
              </div>
              <div class="infomation">
                <label for="infomation">Additional Information</label>
                <textarea
                  name="infomation"
                  id="infomation"
                  v-model="additionalInfo"
                  placeholder="Please provide us with flight information, car seats, etc."
                >
                  {additionalInfo}</textarea>
              </div>
              <div class="infomation seat mt-3">
                <label @click="addSeats()">+ Add Child Seat</label>
                <div v-for="(seat, index) in childSeats" :key="index" id="inputContainer">
                    <div class="input-groups col-md-6">
                        <select v-model="seat.type">
                            <option v-for="(option, i) in seatType" :key="i" :value="option.value">{{option.label}}</option>
                        </select>
                        <button type="button" @click="decreaseValue(index)">-</button>
                        <input type="number" v-model="seat.value" disabled>
                        <button type="button" @click="increaseValue(index)">+</button>
						<i class="fa fa-trash" @click="removeSeat(index)"></i>
                    </div>
                </div>
              </div>

			  <div class="infomation mt-3" style="color: #1e1e1e">
				<i class="fa fa-wheelchair"></i> Accessible <i class="fa fa-question mr-3" title="We collect this data to ensure our vehicles/staff meet your individual needs."></i>
				<label class="switch">
					<input type="checkbox" v-model="isAccessible" @change="getAccessible">
					<span class="slider"></span>
				</label>
			  </div>

              <div class="tables-item mt-5" v-if="estimatedCostData">
                <table class="border">
                  <thead class="bg-2">
                    <tr>
                      <th>Car Type</th>
                      <th>Duration</th>
                      <th>Distance</th>
                      <th>Estimated Cost</th>
                    </tr>
                  </thead>
                  <tbody class="border tableColor">
                    <tr>
                      <td>{{ estimatedCostData.carType }}</td>
                      <td>{{ estimatedCostData.estimatedTime }} Min's</td>
                      <td>{{ estimatedCostData.distance }} Miles</td>
                      <td style="font-weight: 600">
                        ${{ estimatedCostData.estimatedCost }}
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="5">
                        <p class="bold">You are going to pay 20% Gratuity to driver ie.
                        {{ calculateTip }} & {{ booking.carType.categoryId.cleaningPrice }}% cleaning ie. {{ calculateCleaningFee }}</p>
                        <b class="bold">Total cost is {{ totalCostWithTip }}</b>
                        <!-- <p class="bold"> Gratuity not included. Please pay chauffuer.</p> -->
                      </td>
                    </tr>
                  </tfoot>
                </table>

                
              </div>

              <div class="tables-item mt-5" v-if="tripType == 'hourtrip'">
                <table class="border">
                  <thead class="bg-2">
                    <tr>
                      <th>Car Type</th>
                      <th>Duration</th>
                      <th>Cost</th>
                    </tr>
                  </thead>
                  <tbody class="border">
                    <tr>
                      <td>{{ booking.carType.carType }}</td>
                      <td>{{ booking.location.selectedHour }} Hours</td>
                      <td>
                        ${{
                          booking.carType.pricePerUnitHour *
                          booking.location.selectedHour
                        }}
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="5">
                        <p class="bold">You are going to pay 20% Gratuity to driver ie.
                        {{ calculateHourTip }} & {{ booking.carType.categoryId.cleaningPrice }}% cleaning ie. {{ calculateHourCleaningFee }}</p>
                        <b class="bold">Total cost is {{ totalCostWithHourTip }}</b>
                        <!-- <p class="bold"> Gratuity not included. Please pay chauffuer.</p> -->
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>

              <div class="discountBanner" v-if="isExtraDiscount" v-html="extraDiscountText"></div>

              <div class="btn-submit">
                <button
                  type="submit"
                  @click="
                    addressPreFilled
                      ? submitAdditionInfo()
                      : validateCostParams()
                  "
                >
                  {{ addressPreFilled ? "CONTINUE" : "CALCULATE COST" }}
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="col-lg-1"></div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { request } from "../../utils/api";

export default {
  data() {
    return {
      numberPassenger: "1",
      numberBags: "1",
      additionalInfo: "",
      addressPreFilled: false,
      estimatedCostData: "",
      maxPersons: 0,
      maxBags: 0,
      tripType: "",
      isExtraDiscount: false,
      extraDiscountText: '',
      priceWithTip: '',
      discountPrice: '',
      totalTrips: '',
      occasion: '0',
      discountValue: '',
      childSeats: [],
      seatType: [{
        label: 'Infant (ages 0-1)',
        value: 'infant'
      },{
        label: 'Toddler Seat (ages 1-3)',
        value: 'toddler'
      },{
        label: 'Booster Seat (ages 3-6)',
        value: 'booster'
      }
      ],
	  isAccessible: false
    };
  },
  computed: {
    ...mapGetters(["booking", "isLoggedIn", "userId"]),
    calculateTip() {
      return `$${Math.round(((this.estimatedCostData.estimatedCost * 20) / 100))}`;
    },
    totalCostWithTip() {
      let tip = (this.estimatedCostData.estimatedCost * 20) / 100;
      let cleaningCharge = this.calculateCleaningFee.replace(/\$/g, '');
      let costWithGraduation = Math.round((parseFloat(tip) + parseFloat(this.estimatedCostData.estimatedCost) + parseInt(cleaningCharge)));
      this.estimatedCostData.tCost = costWithGraduation
      this.estimatedCostData.cleaningFee = cleaningCharge
      
      this.$store.commit("setEstimatesDetails", this.estimatedCostData);
      return `$${costWithGraduation}`;
    },
    calculateCleaningFee(){
      let gratuityPrice = this.calculateTip.replace(/\$/g, '');
      let costWithGraduation = Math.round((parseFloat(gratuityPrice) + parseFloat(this.estimatedCostData.estimatedCost)));
      return `$${Math.round(((costWithGraduation * this.booking.carType.categoryId.cleaningPrice) / 100))}`;
    },
    calculateHourTip() {
      let price =
        this.booking.carType.pricePerUnitHour *
        this.booking.location.selectedHour;
      return `$${Math.round(((price * 20) / 100))}`;
    },
    totalCostWithHourTip() {
      let price = this.booking.carType.pricePerUnitHour * this.booking.location.selectedHour;
      let tip = (price * 20) / 100;
      let cleaningCharge = this.calculateHourCleaningFee.replace(/\$/g, '');
      let costWithGraduation = Math.round((parseFloat(price) + parseFloat(tip) + parseFloat(cleaningCharge)));
      
      this.$store.commit("setEstimatesDetails", {tCost: costWithGraduation, cleaningFee: cleaningCharge});
      return `$${costWithGraduation}`;
    },
    calculateHourCleaningFee(){
      let gratuityPrice = this.calculateHourTip.replace(/\$/g, '');
      let costWithGraduation = Math.round((parseFloat(gratuityPrice) + parseFloat(this.booking.carType.pricePerUnitHour * this.booking.location.selectedHour)));
      return `$${Math.round(((costWithGraduation * this.booking.carType.categoryId.cleaningPrice) / 100))}`;
    },
  },
  methods: {
	getAccessible(e){
		console.log(e.target.checked)
	},
    addSeats(){
      if(this.childSeats.length === 3){
        return;
      }
      this.childSeats.push({
        type: 'infant',
        value: 1
      })
    },

    increaseValue(index) {
		if(this.childSeats[index].value > 4)
			return;

		this.childSeats[index].value++;
	},
	decreaseValue(index) {
		if (this.childSeats[index].value > 1) {
			this.childSeats[index].value--;
		}
	},
	removeSeat(index){
		this.childSeats.splice(index, 1)
	},
    async estimateCost() {
      const jsonDataPass = {
        source: {
          startLocationAddr:
            this.booking.location.pickUpLocation.formattedAddress,
          startLocation: {
            lat: this.booking.location.pickUpLocation.lat,
            lng: this.booking.location.pickUpLocation.lng,
          },
          addressComponents: {},
        },
        destination: {
          endLocationAddr:
            this.booking.location.dropOffLocation.formattedAddress,
          endLocation: {
            lat: this.booking.location.dropOffLocation.lat,
            lng: this.booking.location.dropOffLocation.lng,
          },
          addressComponents: {},
        },
        carTypeRequired: this.booking.carType._id,
        userId: this.userId,
        isRideMinimum: this.booking.carType.isRideMinimum,
        isReturnWay: this.booking.location.isReturnWay
      };

      if (Object.keys(this.booking.location.stopLocation).length) {
        jsonDataPass.stop = {
          stopLocationAddr: this.booking.location.stopLocation.formattedAddress,
          stopLocation: {
            lat: this.booking.location.stopLocation.lat,
            lng: this.booking.location.stopLocation.lng,
          },
          addressComponents: {},
        };
      }

      const resp = await request({
        method: "POST",
        url: "/api/v1/trip/estimatedcost",
        data: jsonDataPass,
      });

      if (resp.remote == "success") {
        this.estimatedCostData = resp.data;
        this.$store.commit("setEstimatesDetails", this.estimatedCostData);
        this.addressPreFilled = true;

        if(this.userId && this.$matchCarForDiscount(this.booking.carType.carType)){
          this.totalTrips = this.estimatedCostData.totalTrips;
          setTimeout(() => {
            this.verifyDiscount(this.estimatedCostData.estimatedCost)
          }, 1500)
        }
        
      }
    },

    async userTripCount() {
      const jsonDataPass = {
        userId: this.userId
      };

      const resp = await request({
        method: "POST",
        url: "/api/v1/trip/usertotaltrips",
        data: jsonDataPass,
      });

      if (resp.remote == "success") {   
        this.totalTrips = resp.data;
        this.verifyDiscount(this.booking.carType.pricePerUnitHour * this.booking.location.selectedHour);
      }
    },

    // matchCarForDiscount(string){
    //   const conditions = ["suv", "sedan", "ev"];
    //   return conditions.some(el => string.toLowerCase().includes(el));
    // },

    verifyDiscount(price){
        if(this.totalTrips === 0){
          let dis = (price * 10) / 100;
          // let tip = 0;
          let tip = (price * 20) / 100;
          let gratuityPrice = Math.round(((parseFloat(price) + parseFloat(tip))));
          let cleaningPrice = Math.round( (parseInt(gratuityPrice) * parseInt(this.booking.carType.categoryId.cleaningPrice)) / 100 );
          
          this.discountPrice = Math.round(((parseFloat(gratuityPrice) + parseFloat(cleaningPrice)) - parseFloat(dis)));
          this.priceWithTip = Math.round(((parseFloat(gratuityPrice) + parseFloat(cleaningPrice))));

          this.isExtraDiscount = true;
          this.extraDiscountText = `You got 10% discount on your first Ride. You are going to pay <span class="line"> $${this.priceWithTip} </span> $${this.discountPrice}`
          this.discountValue = '10%';
          
          this.estimatedCostData.tCost = this.discountPrice
          this.$store.commit("setEstimatesDetails", this.estimatedCostData);
        }
        else if(this.totalTrips === 4){
          let dis = 30;
          // let tip = 0;
          let tip = (price * 20) / 100;
          let gratuityPrice = Math.round(((parseFloat(price) + parseFloat(tip))));
          let cleaningPrice = Math.round( (parseInt(gratuityPrice) * parseInt(this.booking.carType.categoryId.cleaningPrice)) / 100 );

          let afterdis = (parseFloat(price) - parseFloat(dis));
          this.discountPrice = Math.round(((parseFloat(gratuityPrice) + parseFloat(cleaningPrice)) - parseFloat(dis)));

          if(afterdis <= 0 ){
            this.discountPrice = Math.round(tip + cleaningPrice);
          }
          this.priceWithTip = Math.round(((parseFloat(gratuityPrice) + parseFloat(cleaningPrice))));

          this.isExtraDiscount = true;
          this.discountValue = '$30';
          this.extraDiscountText = `you got $30 discount on your 4th Ride. You are going to pay <span class="line"> $${this.priceWithTip} </span> $${this.discountPrice}`

          this.estimatedCostData.tCost = this.discountPrice
          this.$store.commit("setEstimatesDetails", this.estimatedCostData);
        }
    },

    submitAdditionInfo() {
      const additionInfo = {
        numberPassenger: this.numberPassenger,
        numberBags: this.numberBags,
        additionalInfo: this.additionalInfo,
        isExtraDiscount: this.isExtraDiscount,
        occasion: this.occasion,
		isAccessible: this.isAccessible,
		seats: this.childSeats,
        discountDetails: {
          priceWithTip: this.priceWithTip,
          discountPrice: this.discountPrice,
          totalTrips: this.totalTrips,
          discountValue: this.discountValue
        }
      };

      this.$store.commit("setAdditionInfo", additionInfo);
      if (this.isLoggedIn) {
        this.$router.push({ name: "cards" });
      } else {
        this.$router.push({
          name: "login",
          query: { redirectUrl: this.$router.history.current.name },
        });
      }
    },
  },
  mounted() {
    if (!this.booking.carType) {
      this.$router.push({ name: "booking" });
      return;
    }

    this.maxPersons = this.booking.carType.categoryId.maxPersons;
    this.maxBags = this.booking.carType.categoryId.maxBags;

    if (this.booking.location) {
      if (this.booking.location.type == process.env.VUE_APP_HOUR_TRIP) {
        this.tripType = process.env.VUE_APP_HOUR_TRIP;
        this.addressPreFilled = true;
      //   if(this.userId && this.$matchCarForDiscount(this.booking.carType.carType)){
      //     this.userTripCount();
      //   }
      } else {
        this.estimateCost();
        this.addressPreFilled = true;
        this.tripType = process.env.VUE_APP_ONEWAY_TRIP;
      }
    }

    if (this.booking.additionInfo) {
      this.numberPassenger = this.booking.additionInfo.numberPassenger;
      this.numberBags = this.booking.additionInfo.numberBags;
      this.additionalInfo = this.booking.additionInfo.additionalInfo;
      this.occasion = this.booking.additionInfo.occasion;
      this.isAccessible = this.booking.additionInfo.isAccessible;
      this.childSeats = this.booking.additionInfo?.seats.length > 0 ? this.booking.additionInfo.seats : []

    }
  },
  updated() {
    // console.log(this.menu);
  },
};
</script>

<style scoped>
tfoot {
  background: black;
}
tfoot td {
  padding: 10px;
  color: #fff;
  text-align: center;
}
.tableColor tr td{
  color: #333;
  font-weight: bold;
  font-size: 18px;
}
.bold{
      font-weight: bold;
    font-size: 18px;
}
.discountBanner{
      background: yellow;
    color: red;
    font-weight: 900;
    font-size: 18px;
    text-align: center;
    padding: 15px 10px;
}
.infomation.seat label{
	cursor: pointer;
    color: blue;
}


</style>

<style >

.line{
  text-decoration: line-through;
}
.input-groups {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    /* width: 30%; */
}
.input-groups select,
.input-groups input {
    padding: 5px;
    -webkit-appearance: auto;
    appearance: auto;
    border-radius: 0 0 0 0;
}
.input-groups input {
	width: 20%;
}
.input-groups button {
    padding: 5px;
    border-radius: 0 0 0 0;
    align-items: inherit;
    display: flex;
}
.input-groups i {
	margin-left: 9px;
    color: black;
    font-size: 20px;
}


/* switch */
.switch {
	position: relative;
	display: inline-block;
	width: 53px;
	height: 28px;
}

.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	transition: .4s;
	border-radius: 34px;
}

.slider:before {
	position: absolute;
	content: "";
	height: 20px;
	width: 20px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	transition: .4s;
	border-radius: 50%;
}

input:checked + .slider {
	background-color: #1e1e1e;
}

input:checked + .slider:before {
	transform: translateX(26px);
}
</style>
